<template>
  <div v-loading="loading">
    <div v-if="tableData && tableData.length && !addOrEditingTables">
      <div class="mb-1" style="width: 100%">
        All Data
        <div class="action-links">
          <el-button
            type="danger"
            plain
            icon="el-icon-plus"
            size="mini"
            @click="addNewRow(true)"
          ></el-button>
        </div>
        <div style="margin-top: 20px">
          <el-collapse accordion>
            <el-collapse-item
              name="1"
              v-for="(item, index) in tableData"
              :key="index + '_collapse'"
              class="rule-collapse"
            >
              <template slot="title" style="border: 1px solid #e4e7ed">
                <div style="width: 100%" class="ml-1">
                  {{ item.name }}
                  <div class="action-links">
                    <el-link
                      :underline="false"
                      type="primary"
                      class="mr-1"
                      @click.stop="editRule(index)"
                      ><i class="el-icon-edit"></i>
                    </el-link>
                    <el-link
                      type="danger"
                      :underline="false"
                      @click.stop="onDelete(index)"
                      ><i class="el-icon-delete"></i>
                    </el-link>
                  </div>
                </div>
              </template>
            </el-collapse-item>
          </el-collapse>
        </div>
        <div style="margin-top: 20px" class="action-links">
          <el-button type="primary" plain size="mini" @click="saveAllTables()"
            >Save</el-button
          >
        </div>
      </div>
    </div>
    <div
      class="rule-container"
      v-loading="editLoading"
      :element-loading-text="loadingText"
      v-else-if="addOrEditingTables"
    >
      <!-- <template slot-scope="scope"> -->
      <el-row>
        <el-col :span="24">
          Table Name
          <el-input
            v-model="currentTable.name"
            placeholder="Table name"
            :disabled="checkIsDisabled()"
            class="mb-1"
            size="mini"
            style="width: 80%"
            @change="checkRuleName(currentTable.name)"
          >
          </el-input>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="12">
          Select Entity
          <el-select
            v-model="currentTable.entity_id"
            placeholder="Select Entity"
            filterable
            @change="getEntityFilters"
            size="mini"
          >
            <el-option
              v-for="entity in allEntitiesData"
              :key="entity._id"
              :label="entity.name"
              :value="entity._id"
            ></el-option>
          </el-select>
        </el-col>
        <el-col :span="12">
          Select Type
          <el-select
            v-model="currentTable.type"
            filterable
            size="mini"
            :disabled="!currentTable.entity_id"
            @change="handleTypeChange()"
          >
            <el-option
              v-for="(item, i) in tableTypes"
              :key="item.value + i"
              :label="item.label"
              :value="item.value"
              :disabled="isPdfOptionDisabled(item.value)"
              >{{ item.label }}</el-option
            >
          </el-select>
        </el-col>
      </el-row>
      <el-row style="margin-top: 10px">
        <el-col :span="12">
          Select Filter
          <el-select
            v-loading="filterLoading"
            v-model="currentTable.filters"
            clearable
            size="mini"
          >
            <el-option
              v-for="filter in currentEntityFilters"
              :key="filter._id"
              :label="filter.filter_name"
              :value="filter._id"
              >{{ filter.filter_name }}</el-option
            >
          </el-select>
        </el-col>
        <el-col :span="12">
          Select Fields
          <el-select
            v-loading="filterLoading"
            v-model="currentTable.selectedFields"
            clearable
            filterable
            collapse-tags
            multiple
            :disabled="currentTable.type != 'email_body'"
            size="mini"
            :multiple-limit="12"
          >
            <el-option
              v-for="(field, i) in selectedEntityFields"
              :key="field._id + i"
              :label="field.label"
              :value="field.template_key"
              >{{ field.label }}</el-option
            >
          </el-select>
        </el-col>
      </el-row>
      <div class="action-footer">
        <el-button size="mini" @click="cancelNewRule">Cancel</el-button>
        <el-button
          type="primary"
          size="mini"
          :disabled="checkSaveDisabled"
          @click="saveNewTable"
          >Add</el-button
        >
      </div>
      <!-- </template> -->
    </div>
    <div v-else-if="!tableData.length" class="empty-alert">
      <div>
        <img src="@/assets/img/nodata2.svg" />
      </div>
      <div>
        <el-button
          type="danger"
          icon="el-icon-plus"
          plain
          @click="addNewRow()"
          class="add-rule"
          size="mini"
          >Add Table</el-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import EntityHelper from "@/mixins/EntitiesHelper";
// import { bus } from "../../main";
export default {
  name: "EmailRelationalDataTableConfig",
  props: ["data", "allEntitiesData"],
  mixins: [EntityHelper],
  async mounted() {
    this.tableData = [...this.data];
    console.log("tableData", this.tableData);
  },
  computed: {
    ...mapGetters("auth", [
      "getDefaultDateFormat",
      "getAuthenticatedUser",
      "getUserTypeList",
      "getUserType",
      "getCompanyUsers",
    ]),
    ...mapGetters("users", ["getUsersList"]),
    ...mapGetters("entities", ["getEntityRecordsForTable", "getAllEntities"]),
    ...mapGetters("companyTemplates", [
      "getSingleCompanyTemplate",
      "getAllCompanyTemplatesData",
      "getAllCompanyTemplates",
    ]),
    ...mapGetters("filters", [
      "getAllEntityFilters",
      "getCreateNewFilter",
      "getUpdateFilterStatus",
      "getDeleteFilterStatus",
      "getCreateNewFilterError",
      "getUpdateFilterError",
      "getDeleteFilterErros",
    ]),
    checkSaveDisabled() {
      if (
        !this.currentTable.name ||
        !this.currentTable.entity_id ||
        (this.currentTable.type == 'email_body' &&!this.currentTable.selectedFields?.length)
      ) {
        return true;
      }
      return false;
    },
  },
  methods: {
    cancelNewRule() {
      this.resetCurrentTable();
      this.addOrEditingTables = false;
    },
    addNewRow() {
      this.resetCurrentTable();
      this.currentTableIndex = -1;
      this.addOrEditingTables = true;
    },
    async editRule(index) {
      this.loadingText = "Fetching data...";
      this.addOrEditingTables = true;
      this.editLoading = true;
      let dt = JSON.parse(JSON.stringify(this.tableData[index]));
      await this.getEntityFilters(dt.entity_id);
      this.currentTable.name = dt.name;
      this.currentTable.filters = dt.filters;
      this.currentTable.selectedFields = [...dt.selectedFields];
      this.currentTable.entity_id = dt.entity_id;
      this.currentTable.type = dt.type;
      this.currentTableIndex = index;
      this.editLoading = false;
    },
    onDelete(index) {
      this.$confirm("Are you sure to delete the Table?", "Warning", {
        confirmButtonText: "OK",
        cancelButtonText: "Cancel",
        type: "warning",
      }).then(() => {
        this.deleteRule(index);
      });
    },
    deleteRule(index) {
      console.log("indexxx", index);
      this.addOrEditingTables = false;
      this.tableData.splice(index, 1);

      if (!this.tableData?.length) {
        this.resetCurrentTable();
      }
    },

    checkRuleName(event) {
      let ruleNameCheck = this.tableData.filter(
        (el) => el && el.name === event
      );
      if (ruleNameCheck && ruleNameCheck?.length) {
        this.$message.error("Name already exists!");
        this.currentTable.name = "";
      } else if (/^\s*$/.test(event)) {
        this.$message.error("name should not be empty or contain only spaces!");
        this.currentTable.name = "";
      }
    },
    saveNewTable() {
      this.addOrEditingTables = false;
      console.log("currentTable", this.currentTable);
      if (this.currentTableIndex == -1) {
        this.tableData.push(this.currentTable);
      } else {
        this.tableData[this.currentTableIndex] = { ...this.currentTable };
      }
    },
    resetCurrentTable() {
      this.currentTable = {
        name: "",
        entity_id: "",
        filters: "",
        selectedFields: [],
        type: "email_body",
      };
      this.currentEntityFilters = [];
      this.selectedEntityFields = [];
    },
    async getEntityFilters(entity_id) {
      this.loadingText = "Fetching filters";
      this.filterLoading = true;
      this.selectedEntity = this.allEntitiesData.find(
        (e) => e._id == entity_id
      );
      this.currentTable["filters"] = "";
      this.currentTable["type"] = "email_body";
      this.currentTable["selectedFields"] = [];
      if (this.currentTable.document_id) {
        this.currentTable["document_id"] = "";
      }
      console.log("selected Entity", this.selectedEntity);
      await this.$store.dispatch("filters/fetchAllEntityFilters", {
        entityId: entity_id,
      });
      this.currentEntityFilters = [];
      if (this.getAllEntityFilters) {
        this.currentEntityFilters = this.getAllEntityFilters;
      }
      this.currentTable["selectedFields"] = [];
      this.selectedEntityFields = await this.fetchEntityDetails(
        entity_id,
        true,
        true
      );
      this.selectedEntityFields = this.selectedEntityFields.filter(
        (e) =>
          e.properties?.filed_content != "Hide" && e.input_type != "DATA_TABLE"
      );
      this.filterLoading = false;
    },
    saveAllTables() {
      this.$emit("save-all-tables", this.tableData);
    },
    checkIsDisabled() {
      console.log(
        "currentTableIndex > 0currentTableIndex > 0",
        this.currentTableIndex
      );
      return this.currentTableIndex == -1 ? false : true;
    },
    handleTypeChange() {
      if (this.currentTable.type == "attachment_as_pdf") {
        this.currentTable["document_id"] =
          this.selectedEntity.export_printing_document;
        this.currentTable.selectedFields =[]
      }
    },
    isPdfOptionDisabled(type) {
      if (
        type == "attachment_as_pdf" &&
        !this.selectedEntity?.export_printing_document
      ) {
        return true;
      }
      return false;
    },
  },
  data() {
    return {
      loading: false,
      currentTable: {
        name: "",
        entity_id: "",
        filters: "",
        selectedFields: [],
        type: "email_body",
      },
      globalVariablesList: [],
      currentTableIndex: -1,
      addOrEditingTables: false,
      currentEntityFilters: [],
      selectedEntityFields: [],
      filterLoading: false,
      tableTypes: [
        {
          label: "Table in Email Body",
          value: "email_body",
        },
        {
          label: "Attachment as Excel",
          value: "attachment_as_excel",
        },
        {
          label: "Attachment as Pdf",
          value: "attachment_as_pdf",
        },
      ],
      editLoading: false,
      loadingText: "",
      tableData: [],
      selectedEntity: null,
    };
  },

  //   created() {
  //     bus.$on("form-rule-dialog-closed", (data) => {
  //       console.log("Form rule closed", data);
  //       if (data) {
  //         if (this.tableData.length) {
  //           //do action when template rules exist
  //         } else {
  //           this.addOrEditingTables = false;
  //           this.currentTable = {
  //             name: "",
  //             entity_id: "",
  //             filters: "",
  //             selectedFields: [],
  //             type : "email_body"
  //           };
  //         }
  //       }
  //     });
  //   },
};
</script>

<style lang="scss" scoped>
.action-links {
  float: right !important;
}
.small-input {
  width: 180px;
}

.rule-collapse {
  border: 1px solid #e4e7ed;
  border-radius: 3px;
}

.empty-alert {
  text-align: center !important;

  img {
    width: 100px;
    margin-bottom: 10px;
  }

  .add-rule {
  }
}

.rule-row {
  display: flex !important;
  background: #f2f6fc;
  height: 55px;

  .rule-inner-row {
    display: grid;
    margin: auto;
    margin-right: 25px;
    margin-left: 25px;
    grid-template-columns: auto auto auto auto;
  }
}

.action-footer {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.rule-preview {
  padding: 10px;
  font-size: 12px;

  img {
    width: 25px;
  }
}

.el-date {
}
</style>
